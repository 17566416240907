import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { RenderIf, Carausel } from "shared/components";
import { clsx } from "shared/helper/utilis";
import "./Grid.scss";
const ImageGrid = ({
  forComment,
  children: elements,
  showModal = true,
  className = "",
  smart = false,
}) => {
  const [children, setChildren] = useState();
  const numberOfImages = Array.isArray(children) ? children.length : 1;
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isOpenCarausel, setIsOpenCarausel] = useState(false);
  const [images, setImages] = useState([]);
  const handleImageClick = (imageIndex) => {
    if (numberOfImages > 0) {
      setIsOpenCarausel(true);
    }
    return imageIndex;
  };
  const handleCloseCarausel = () => {
    setSelectedImageIndex(0);
    setIsOpenCarausel(false);
  };

  useEffect(() => {
    if (images?.length === numberOfImages && smart) {
      setChildren(
        images
          .filter((ele) => ele.element)
          .sort(
            (a, b) =>
              Math.abs(a.dimensions.right - a.dimensions.left) -
              Math.abs(b.dimensions.right - b.dimensions.left)
          )
          .map(({ element }) => element)
      );
    }
  }, [images]);
  useEffect(() => {
    if (elements?.length) {
      setChildren(elements);
    }
  }, [elements]);

  if (numberOfImages < 3) {
    return (
      <>
        {smart &&
          React.Children.map(children, (child) =>
            React.cloneElement(child, {
              ref: (element) => {
                if (images.length >= numberOfImages) return;
                if (!element) return;
                setImages((prev) => [
                  ...prev,
                  {
                    element: child,
                    dimensions: element?.getBoundingClientRect() || {},
                  },
                ]);
              },
              style: {
                visibility: "hidden",
                position: "absolute",
              },
            })
          )}
        {typeof showModal === "boolean" && showModal && (
          <Carausel
            forComment
            key={uuid()}
            isOpen={isOpenCarausel}
            images={numberOfImages === 0 ? [children] : children}
            initialImageIndex={selectedImageIndex}
            onClose={handleCloseCarausel}
          />
        )}

        <div
          className={clsx(
            "grid_wrapper",
            `grid_wrapper_${numberOfImages}_img`,
            React.Children.toArray(children).some(
              (child) => child.type === "video"
            ) && `grid_wrapper_${numberOfImages}_video`,
            className
          )}>
          {React.Children.map(children, (child, i) => {
            const imgCount = i + 1;
            return React.cloneElement(child, {
              ...child.props,
              className: ` ${"img"} ${`img_${numberOfImages}_${imgCount}`} ${
                child.props?.className || ""
              }`,
              onClick: () => {
                child.props.onClick?.();
                setSelectedImageIndex(handleImageClick(i));
              },
            });
          })}
        </div>
      </>
    );
  } else {
    const firstRow = children.filter((_, i) => i < 2);
    const firstRowImgCount = firstRow.length;
    const secondRow = children.filter((_, i) => i >= 2 && i <= 4);
    const secondRowImgCount = secondRow.length;

    return (
      <>
        <RenderIf condition={smart}>
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              ref: (element) => {
                if (images.length >= numberOfImages) return;
                if (!element) return;
                setImages((prev) => [
                  ...prev,
                  {
                    element: child,
                    dimensions: element?.getBoundingClientRect() || {},
                  },
                ]);
              },
              style: {
                visibility: "hidden",
                position: "absolute",
              },
            })
          )}
        </RenderIf>
        <RenderIf condition={typeof showModal === "boolean" && showModal}>
          <Carausel
            forComment
            key={uuid()}
            isOpen={isOpenCarausel}
            initialImageIndex={selectedImageIndex}
            images={numberOfImages === 1 ? [children] : children}
            onClose={handleCloseCarausel}
          />
        </RenderIf>

        <div className={clsx("sub_grid_wrapper", className)}>
          <div
            className={clsx(
              "sub_grid_wrapper_first_row",
              `grid_wrapper_${firstRow.length}_img`,
              React.Children.toArray(children).some(
                (child) => child.type === "video"
              ) && `grid_wrapper_${numberOfImages}_video`
            )}>
            {React.Children.map(firstRow, (child, i) => {
              const imgCount = i + 1;
              return React.cloneElement(child, {
                ...child.props,
                className: ` ${"img"} ${`img_${firstRowImgCount}_${imgCount}`} ${
                  child.props?.className || ""
                }`,
                onClick: () => {
                  child.props.onClick?.();
                  setSelectedImageIndex(handleImageClick(i));
                },
              });
            })}
          </div>
          <div
            className={clsx(
              "sub_grid_wrapper_second_row",
              `sub_grid_wrapper_${secondRow.length}_img`
            )}>
            {React.Children.map(secondRow, (child, i) => {
              const imgCount = i + 1;

              if (numberOfImages > 5 && imgCount === 3) {
                return (
                  <div
                    className={clsx(
                      "img",
                      `sub_img_${secondRowImgCount}_${imgCount}`,
                      child.props?.className || "",
                      "show_more_img"
                    )}
                    onClick={() => setSelectedImageIndex(handleImageClick(0))}>
                    {React.cloneElement(child, {
                      ...child.props,
                      className: ` ${"img"} ${`sub_img_${secondRowImgCount}_${imgCount}`} ${
                        child.props?.className || ""
                      }`,
                      onClick: () => {
                        child.props.onClick?.();
                        setSelectedImageIndex(handleImageClick(i + 2));
                      },
                    })}
                    <div className={"show_more_text"}>
                      {" "}
                      + {numberOfImages - 5}{" "}
                    </div>
                  </div>
                );
              }

              return React.cloneElement(child, {
                ...child.props,
                className: ` ${"img"} ${`sub_img_${secondRowImgCount}_${imgCount}`} ${
                  child.props?.className || ""
                }`,
                onClick: () => {
                  child.props.onClick?.();
                  setSelectedImageIndex(handleImageClick(i + 2));
                },
              });
            })}
          </div>
        </div>
      </>
    );
  }
};

export default ImageGrid;
