import React, { useEffect, useState, useRef } from "react";
import { MentionsInput, Mention } from "react-mentions";
import Tooltip from "@mui/material/Tooltip";
import { v4 as uuid } from "uuid";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Member from "shared/media/user/userIcon.png";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import {
  useDeleteDiscussionMutation,
  useSendDiscussionMutation,
  useSendCommentsMutation,
  useReportDiscussionMutation,
} from "redux/api/discussion";
import {
  useDetailedInfoByIdQuery,
  useGetAllUsersQuery,
} from "redux/api/siteUsers";
import { Urls } from "shared/constants/url";
import { updateObject } from "shared/helper";
import {
  Send,
  Send2,
  MessageQuestion,
  Bookmark,
  Like,
  DownArrow,
  Eye,
  FilePpt,
  Filedoc,
  Filepdf,
  Filexls,
  Download,
} from "shared/components/Icons";
import {
  RenderIf,
  SearchInput,
  Button,
  ClickMore,
  EditPostCard,
  DiscussionComment,
  ImageGrid,
  File,
  UserCard,
  LikeList,
} from "shared/components";
import { calculateTimeAgo } from "shared/helper/formatDate";
import { handleDownload } from "shared/helper/updateObject";
import {
  getYouTubeVideoId,
  processTextWithLinks,
} from "shared/helper/getYoutubeVideoId";
import { useLazyGetFileQuery } from "redux/api/news";
import {
  imgTypes,
  mainDocType,
  videoTypes,
  docType,
} from "shared/constants/url";
import {
  useLazyGetCommentsByPostQuery,
  useDeleteDiscussionLikeMutation,
  useDiscussionLikeMutation,
  useCreatePostViewMutation,
} from "redux/api/discussion";
import {
  useBookmarkMutation,
  useDeleteBookmarkMutation,
} from "redux/api/bookmark";
import { custom, customStyleSecond } from "../DiscussionComment/style";
import "./Card.scss";
import { useLazyGetDiscussionDetailQuery } from "redux/api/discussion/index";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const PostCard = ({
  files,
  discussionInterests,
  comments,
  userImg,
  advice,
  name,
  surname,
  time,
  mission,
  description,
  postID,
  siteUserID,
  refetch,
  className,
  cardClickHandler,
  isLiked,
  likeCount,
  viewCount,
  isBookmarked,
  discussionId,
  discussionLikes,
}) => {
  //REDUX & PROCESS ENV
  const navigate = useNavigate();
  const { id: ownerID } = useSelector((state) => state.user.user);
  const { REACT_APP_BASE_PHOTO_URL, REACT_APP_BASE_PHOTO_URL_MAIN } =
    process.env;
  const { t } = useTranslation();
  const [createPostView] = useCreatePostViewMutation();
  const [showList, setShowList] = useState(false);
  const videoRefs = useRef([]);
  const { id } = useSelector((state) => state.user.user);
  const [fileData, setFileData] = useState(null);
  const [discussionFile, setDiscussionFile] = useState();
  const [interests, setInterests] = useState();
  const [selectedUserEmails, setSelectedUserEmails] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [partsOther, setPartsOther] = useState([]);
  const [showMore, setShowMore] = useState(true);
  const [modal, setModal] = useState(false);
  const [inputVal, setInputVal] = useState("");
  const [editInputVal, setEditInputVal] = useState();
  const [selectedId, setSelectedId] = useState([]);
  const [suggestion, setSuggettion] = useState([]);
  const [filterInputs, setFilterInputs] = useState({
    firstName: "",
  });
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [searchResults, setSearchResults] = useState([]);
  const [deleteDiscussion, { isSuccess }] = useDeleteDiscussionMutation();
  const { data: usersEmail, isSuccess: usersSuccess } = useGetAllUsersQuery({
    name: filterInputs,
  });
  const [getCommentsByPost, { data: commentData, isLoading: commentLoading }] =
    useLazyGetCommentsByPostQuery();
  const [getFile, { isFetching: fileLoading }] = useLazyGetFileQuery();

  const [
    sendDiscussion,
    { isLoading: sendLoading, isSuccess: sendSuccess, error },
  ] = useSendDiscussionMutation();
  const [sendComments, { isSuccess: CommentSuccess }] =
    useSendCommentsMutation();
  const [discussionLike, { isSuccess: likeSuccess }] =
    useDiscussionLikeMutation();
  const [deleteDiscussionLike, { isSuccess: deleteLikeSuccess }] =
    useDeleteDiscussionLikeMutation();
  const [bookmark, { isSuccess: bookmarkSuccess }] = useBookmarkMutation();
  const [deleteBookmark, { isSuccess: deleteSuccess }] =
    useDeleteBookmarkMutation();
  const [
    getDiscussionDetail,
    {
      data: discussionData,
      isSuccess: discussionDataSuccess,
      isLoading: discussionDataLoading,
    },
  ] = useLazyGetDiscussionDetailQuery();

  const [
    reportDiscussion,
    {
      isSuccess: reportSuccess,
      isError: reportError,
      isLoading: reportLoading,
    },
  ] = useReportDiscussionMutation();

  const { data } = useDetailedInfoByIdQuery({ id: ownerID });
  const handleTagClick = (id) => {
    setSelectedId((prevIds) => [...prevIds, id]);
  };

  let formattedMessage = inputVal;
  selectedId?.forEach((id, index) => {
    const regex = new RegExp(`@\\[.*?\\]\\(${id}\\)`, "g");
    formattedMessage = formattedMessage.replace(regex, `@${id}`);
  });

  const handleCheckboxChange = (event, email, id) => {
    if (event.target.checked) {
      setSelectedUserEmails((prevSelectedUserEmails) => [
        ...prevSelectedUserEmails,
        email,
      ]);
      setSelectedUserIds((prevSelectedUserEmails) => [
        ...prevSelectedUserEmails,
        id,
      ]);
    } else {
      setSelectedUserEmails((prevSelectedUserEmails) =>
        prevSelectedUserEmails.filter((item) => item !== email)
      );
      setSelectedUserIds((prevSelectedUserEmails) =>
        prevSelectedUserEmails.filter((item) => item !== id)
      );
    }
  };

  const getImagesFromFile = (value) => {
    setFileData(value);
  };
  const likePost = () => {
    const formData = new FormData();
    formData.append("discussionId", postID);
    discussionLike(formData);
    createPostView({
      discussionId: postID,
    });
  };
  const deleteLike = () => {
    const data = {
      discussionId: postID,
    };
    createPostView({
      discussionId: postID,
    });
    deleteDiscussionLike(data);
  };
  const saveBookmark = () => {
    const formData = new FormData();

    formData.append("DiscussionId", postID);

    bookmark(formData);
    createPostView({
      discussionId: postID,
    });
  };
  const deleteBookmarked = () => {
    const data = {
      discussionId: postID,
    };
    deleteBookmark(data);
    createPostView({
      discussionId: postID,
    });
  };
  const deletePost = () => {
    const data = {
      id: postID,
    };
    deleteDiscussion(data);
  };
  const titles = [
    {
      id: uuid(),
      title: t("edit"),
      onClick: () => {
        setShow(true);
        createPostView({
          discussionId: postID,
        });
        getDiscussionDetail(postID);
        setDiscussionFile(discussionData?.discussionFiles);
        setEditInputVal(discussionData?.text);
        setInterests(discussionData?.discussionInterests);
      },
    },
    {
      id: uuid(),
      title: t("delete"),
      onClick: deletePost,
    },
  ];

  const sendComment = () => {
    const formData = new FormData();
    if (fileData?.length) {
      for (let i = 0; i < fileData?.length; i++) {
        formData.append(`Files`, fileData?.[i]);
      }
    } else {
      formData.append(`files`, null);
    }
    formData.append("Description", formattedMessage);
    formData.append("SiteUserId", id);
    formData.append("DiscussionId", postID);
    let condition = !inputVal?.trim() && !fileData?.length;
    !condition && sendComments(formData);
  };

  const filterInputsUpdate = (e) => {
    const { name, value } = e.target;
    const updateFiltersInput = updateObject(filterInputs, {
      [name]: value,
    });
    setFilterInputs(updateFiltersInput);
    let filteredUsers = [];
    if (value === "") {
      filteredUsers = [];
    } else {
      filteredUsers = usersEmail?.data?.filter((user) =>
        `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(updateFiltersInput[name].toLowerCase())
      );
    }
    setSearchResults(filteredUsers);
  };
  const sendDiscussionPost = () => {
    const data = {
      discussionId: postID,
      emails: selectedUserEmails,
      ids: selectedUserIds,
    };
    selectedUserEmails.length && sendDiscussion(data);
  };

  const reportDiscussionPost = () => {
    const formData = new FormData();

    formData.append("SiteUserId", id);
    formData.append("DiscussionId", postID);
    reportDiscussion(formData);
  };
  const onHideModal = () => {
    setModal(false);
    setSelectedUserEmails([]);
    setSearchResults([]);
    setSelectedUserIds([]);
    setFilterInputs({
      firstName: "",
      lastName: "",
    });
  };
  function handleLoadMore() {
    setRowsPerPage(rowsPerPage + 3);
    getCommentsByPost({
      discussionId: postID,
      skip: 0,
      take: rowsPerPage + 3,
      orderBy: false,
      sortField: "id",
    });
  }
  const imageClickHandler = (e) => {
    e.stopPropagation();
  };
  useEffect(() => {
    if (sendSuccess) {
      setModal(false);
      setSelectedUserEmails([]);
      setFilterInputs({
        firstName: "",
        lastName: "",
      });
      setSearchResults([]);
      setSelectedUserIds([]);
      toast.success("Send successfully !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [sendSuccess]);
  useEffect(() => {
    if (error) {
      toast.error("Please try again !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [error]);
  useEffect(() => {
    if (reportSuccess) {
      toast.success("Send successfully !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (reportError) {
      toast.error("Please try again !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [reportLoading]);

  useEffect(() => {
    if (CommentSuccess) {
      setFileData(null);
      setInputVal("");
      toast.success("Send successfully !", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [CommentSuccess]);

  useEffect(() => {
    const formattedData = usersEmail?.data?.map((user) => ({
      id: user.id,
      display: `${user.firstName} ${user.lastName}`,
    }));
    setSuggettion(formattedData);
  }, [usersSuccess]);

  useEffect(() => {
    const extractLinks = (text) => {
      if (!text) {
        return [];
      }

      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const matches = text.match(urlRegex);

      if (!matches) {
        return [];
      }

      const parts = text.split(urlRegex);

      const links = parts.reduce((acc, part, index) => {
        if (matches.includes(part)) {
          acc.push(part);
        }
        return acc;
      }, []);

      return links;
    };

    const extractedLinks = extractLinks(description);
    setPartsOther(extractedLinks);
  }, [description]);
  useEffect(() => {
    if (sendSuccess) {
      setModal(false);
      setSelectedUserEmails([]);
      setFilterInputs({
        firstName: "",
        lastName: "",
      });
      setSearchResults([]);
    }
  }, [sendSuccess]);
  useEffect(() => {
    if (isSuccess) {
      navigate(Urls.DISCUSSIONS_URL);
    }
  }, [isSuccess]);
  useEffect(() => {
    if (deleteSuccess || bookmarkSuccess || deleteLikeSuccess || likeSuccess) {
      refetch && refetch();
    }
  }, [deleteSuccess, bookmarkSuccess, deleteLikeSuccess, likeSuccess]);
  useEffect(() => {
    if (discussionDataSuccess) {
      setDiscussionFile(discussionData?.discussionFiles);
      setEditInputVal(discussionData?.text);
      setInterests(discussionData?.discussionInterests);
    }
  }, [discussionData, postID, discussionDataSuccess]);

  return (
    <div className={`cardMain ${className}`}>
      <div
        onClick={cardClickHandler}
        className="cardMainTop d-flex justify-content-between">
        <div className="d-flex">
          <div className="cardMainTopLeft">
            <Link
              to={Urls.POSTS_URL + `/${siteUserID}`}
              onClick={imageClickHandler}>
              <img
                alt="user"
                src={userImg ? `${REACT_APP_BASE_PHOTO_URL}${userImg}` : Member}
              />
            </Link>
          </div>
          <div className="cardMainTopRight">
            <Link
              to={Urls.POSTS_URL + `/${siteUserID}`}
              onClick={imageClickHandler}>
              <h4 className="name">
                {name} {surname}
              </h4>{" "}
            </Link>
            <span className="time">{calculateTimeAgo(time)}</span>
            <p className="duty">{mission}</p>
          </div>
        </div>
      </div>
      <div className="cardMainCenter">
        <RenderIf condition={description}>
          <div className="cardMainCenterText emojiField">
            <p>
              <RenderIf
                condition={files?.length}
                renderElse={
                  <>
                    <div style={{ whiteSpace: "pre-line" }}>
                      {processTextWithLinks(description)}
                      {partsOther?.map((link, index) => {
                        if (
                          link?.startsWith("https://www.youtube") ||
                          link?.startsWith("https://youtube") ||
                          link?.startsWith("https://youtu.be")
                        ) {
                          const shortVideoID = link.split("/").slice(-1)[0];
                          const videoId = getYouTubeVideoId(link);
                          const embedUrl =
                            link?.startsWith(
                              "https://www.youtube.com/shorts"
                            ) || link?.startsWith("https://youtube")
                              ? `https://www.youtube.com/embed/${shortVideoID}`
                              : `https://www.youtube.com/embed/${videoId}`;
                          return (
                            <div className="mt-2" key={index}>
                              <iframe
                                width="80%"
                                height="400"
                                src={embedUrl}
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                title="YouTube Video"
                                allowFullScreen></iframe>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </>
                }>
                <RenderIf
                  condition={showMore}
                  renderElse={
                    <div
                      onClick={() => setShowMore(!showMore)}
                      style={{ whiteSpace: "pre-line" }}>
                      {" "}
                      {processTextWithLinks(description)}
                    </div>
                  }>
                  <div
                    onClick={() => setShowMore(!showMore)}
                    style={{ whiteSpace: "pre-line" }}>
                    {" "}
                    {processTextWithLinks(description?.substring(0, 250))}
                  </div>
                </RenderIf>
              </RenderIf>
            </p>

            <RenderIf condition={files?.length}>
              <RenderIf condition={description?.length > 250}>
                <button onClick={() => setShowMore(!showMore)} className="more">
                  {showMore ? "See more..." : "See less..."}
                </button>
              </RenderIf>
            </RenderIf>
          </div>
        </RenderIf>
        <RenderIf condition={discussionInterests?.length}>
          <div className="d-flex flex-wrap align-items-center mt-4 mb-2">
            {discussionInterests?.map((c) => (
              <div key={c?.id} className="cardMainCenterTextTag">
                <span>{`#${c?.name}`}</span>
              </div>
            ))}
          </div>
        </RenderIf>
        <RenderIf condition={files?.length}>
          <div className="mt-3">
            <>
              {files?.some((item) => {
                const extension = item.fileUrl.split(".").pop().toLowerCase();
                return docType.includes(extension);
              }) && (
                <div className="d-flex mb-3">
                  {files
                    .filter((item) => {
                      const extension = item.fileUrl
                        .split(".")
                        .pop()
                        .toLowerCase();
                      return docType.includes(extension);
                    })
                    .map((item, index) => {
                      const extension = item.fileUrl
                        .split(".")
                        .pop()
                        .toLowerCase();

                      const fileIcons = {
                        doc: <Filedoc width="60px" height="60px" />,
                        docx: <Filedoc width="60px" height="60px" />,
                        pdf: <Filepdf width="60px" height="60px" />,
                        pptx: <FilePpt width="60px" height="60px" />,
                        ppt: <FilePpt width="60px" height="60px" />,
                        xlsx: <Filexls width="60px" height="60px" />,
                        xls: <Filexls width="60px" height="60px" />,
                      };
                      const FileComponent = fileIcons[extension] || null;
                      return (
                        <div key={index} className="svg-wrapper mt-2">
                          {FileComponent}
                          <div
                            onClick={() => {
                              handleDownload(item?.id, item?.fileUrl, getFile);
                            }}
                            className="svg-wrapper-svg">
                            <Download />
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
              {files?.some((item) => {
                const extension = item.fileUrl.split(".").pop().toLowerCase();
                return (
                  imgTypes.includes(extension) || videoTypes.includes(extension)
                );
              }) && (
                <div
                  style={{
                    width: "100%",
                  }}>
                  <ImageGrid>
                    {files
                      .filter((item) => {
                        const extension = item.fileUrl
                          .split(".")
                          .pop()
                          .toLowerCase();
                        return (
                          imgTypes.includes(extension) ||
                          videoTypes.includes(extension)
                        );
                      })
                      // .reverse()
                      .map((item, index) => {
                        const extension = item.fileUrl
                          .split(".")
                          .pop()
                          .toLowerCase();
                        if (imgTypes.includes(extension)) {
                          return (
                            <img
                              key={item?.id}
                              src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${item.fileUrl}`}
                              alt="img"
                            />
                          );
                        } else if (videoTypes.includes(extension)) {
                          return (
                            <video key={item?.id} controls>
                              <source
                                src={`${REACT_APP_BASE_PHOTO_URL_MAIN}${item.fileUrl}`}
                              />
                            </video>
                          );
                        }
                      })}
                  </ImageGrid>
                </div>
              )}
              <div
                style={{
                  marginTop: "20px",
                }}>
                {files
                  ?.filter((item) => {
                    const extension = item.fileUrl
                      .split(".")
                      .pop()
                      .toLowerCase();
                    return (
                      !imgTypes.includes(extension) &&
                      !videoTypes.includes(extension) &&
                      !mainDocType.includes(extension) &&
                      extension !== "pdf"
                    );
                  })
                  .map((item) => (
                    <p key={item?.id}>{item.fileUrl}</p>
                  ))}
              </div>
            </>
          </div>
        </RenderIf>
      </div>
      <div className="cardMainBottom align-items-center justify-content-between">
        <div className="d-flex align-items-center cardBottom">
          <div className="cardMainBottomIcon d-flex align-items-center">
            <Tooltip title={t("like")}>
              <div
                onClick={isLiked ? deleteLike : likePost}
                className={`${
                  isLiked ? "like-active" : ""
                } d-flex align-items-center`}>
                <Like />
                <div className="d-flex align-items-center">
                  <span> {t("like")}</span>
                  <span>({likeCount})</span>
                </div>
              </div>
            </Tooltip>
          </div>

          <Tooltip title={t("share")}>
            <div
              onClick={() => {
                setModal(true);
                createPostView({ discussionId: postID });
              }}
              className="cardMainBottomIcon">
              <Send2 />
              <span> {t("share")}</span>
            </div>
          </Tooltip>
          <Tooltip title={t("comment")}>
            <div
              onClick={() => {
                createPostView({
                  discussionId: postID,
                });

                setToggle(!toggle);
                getCommentsByPost({
                  discussionId: postID,
                  skip: 0,
                  take: rowsPerPage,
                  orderBy: false,
                  sortField: "id",
                });
              }}
              className="cardMainBottomIcon">
              <Send />
              <span> {t("comment")}</span>
              <span>({comments?.length || 0})</span>
            </div>
          </Tooltip>
          <Tooltip title={t("Bookmark")}>
            <div
              onClick={isBookmarked ? deleteBookmarked : saveBookmark}
              className={`${
                isBookmarked ? "like-active" : ""
              } cardMainBottomIcon`}>
              <Bookmark />
              <span> {t("Bookmark")}</span>
            </div>
          </Tooltip>
          <Tooltip title={t("reportIt")}>
            <div onClick={reportDiscussionPost} className="cardMainBottomIcon">
              <MessageQuestion />
              <span> {t("reportIt")}</span>
            </div>
          </Tooltip>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center newsCardOneRightBottomIcon mx-2">
            <Eye />{" "}
            <span className="mx-2">
              {viewCount} {t("view")}
            </span>
          </div>
          <RenderIf condition={ownerID === siteUserID}>
            <div>
              <ClickMore background="#F3F8F6" buttonTitles={titles} />
              <EditPostCard
                interests={interests}
                show={show}
                close={() => setShow(false)}
                inputVal={editInputVal}
                setInputVal={setEditInputVal}
                postID={postID}
                disType={advice}
                siteUserID={siteUserID}
                name={name}
                surname={surname}
                filer={discussionFile}
                setDiscussionFile={setDiscussionFile}
                refetch={refetch}
              />
            </div>
          </RenderIf>
        </div>
      </div>
      <RenderIf condition={toggle}>
        <div className="cardMainComment">
          <div className="cardMainCommentInput d-flex ">
            <div className="cardMainCommentInputLeft">
              <img
                alt="user"
                src={
                  data?.profilePhotoUrl
                    ? `${REACT_APP_BASE_PHOTO_URL}${data?.profilePhotoUrl}`
                    : Member
                }
              />
            </div>
            <div className="cardMainCommentInputRight">
              <MentionsInput
                autoFocus={false}
                value={inputVal}
                onChange={(e) => setInputVal(e.target.value)}
                placeholder={t("writeComment")}
                style={customStyleSecond}>
                <Mention
                  key={() => uuid()}
                  trigger="@"
                  data={suggestion}
                  onAdd={handleTagClick}
                  style={custom}
                />
              </MentionsInput>

              <File
                commentSuccess={CommentSuccess}
                handleImageFile={getImagesFromFile}
              />
              <button
                className="sendButton"
                onClick={sendComment}
                type="submit">
                <Send2 />
              </button>
            </div>
          </div>

          {commentData?.map((comment) => (
            <DiscussionComment
              discussionId={discussionId}
              isLiked={comment?.isLiked}
              likeCount={comment?.likeCount}
              key={comment?.id}
              commentLoading={commentLoading}
              siteUserId={comment?.siteUserId}
              userName={comment?.siteUserName}
              userPhoto={comment?.siteUserFileUrl}
              createDate={comment?.createdDate}
              newsCommentFiles={comment?.discussionCommentFiles}
              description={comment?.text}
              commentId={comment?.id}
              refetch={refetch}
            />
          ))}
          <RenderIf condition={commentData?.length}>
            <Button
              onClick={handleLoadMore}
              className="showMoreButton mt-3"
              marginRight="8px"
              icon={<DownArrow />}>
              {t("showMore")}
            </Button>
          </RenderIf>
        </div>
      </RenderIf>
      <Modal
        keyboard={false}
        backdrop="static"
        show={modal}
        onHide={onHideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("sendPost")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="sendList">
            <SearchInput
              filterInputsUpdate={filterInputsUpdate}
              filterInputs={filterInputs?.firstName}
              setFilterInputs={setFilterInputs}
              placeholder={t("search")}
              name="firstName"
            />
            <div className="sendListBody">
              {searchResults?.map((c) => (
                <div key={c?.id} className="sendListBodyItem d-flex">
                  <div className="sendListBodyItemLeft">
                    <UserCard
                      image={
                        c?.profilePhotoUrl
                          ? `${REACT_APP_BASE_PHOTO_URL}${c?.profilePhotoUrl}`
                          : Member
                      }
                      userName={c?.firstName}
                      userLastName={c?.lastName}
                      profession={c?.position}
                    />
                  </div>
                  <div className="sendListBodyItemRight">
                    <Checkbox
                      {...label}
                      color="success"
                      checked={selectedUserEmails.includes(c?.email)}
                      onChange={(e) => handleCheckboxChange(e, c?.email, c?.id)}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              if (!sendLoading) {
                sendDiscussionPost();
              }
            }}
            className="pt_14_26 font_14 align-items-center d-flex"
            background="#057953"
            disabled={!selectedUserIds?.length || sendLoading}>
            {t("share")}
            <RenderIf condition={sendLoading}>
              <div class="spinner-border" role="status"></div>{" "}
            </RenderIf>
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <LikeList
        show={showList}
        onHide={() => setShowList(false)}
        userList={discussionLikes}
      /> */}
    </div>
  );
};

export default PostCard;
